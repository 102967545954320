<template>
  <div>
    <vineyards-component />
    <footer-component />
  </div>
</template>

<script>
import VineyardsComponent from "@/components/vineyards/VineyardsComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "Vineyards",
  title: "Top 10 Vineyards | Baja California Health Tourism",
  components: { FooterComponent, VineyardsComponent },
  mounted() {
    changeInformationSEO();
  }
};
</script>

<style scoped>
</style>
